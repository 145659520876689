import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThunkActionDispatch } from 'redux-thunk';
import { LoadingScreen } from '@mesa-labs/mesa-ui';

import { useDispatch, useSelector } from '../../redux/hooks';
import { AuthenticationGroup, setupCognito } from '../../redux/slices/auth';

type CognitoRouteProps = {
  children: React.ReactElement;
  redirectToSignIn?: boolean | string;
  authorizedRoles?: AuthenticationGroup[];
};

function CognitoRoute({
  children,
  redirectToSignIn,
  authorizedRoles = [],
}: CognitoRouteProps): React.ReactElement {
  const dispatch = useDispatch() as ThunkActionDispatch<any>;
  const navigate = useNavigate();
  const location = useLocation();
  /** @TODO this boolean should also depend on whether
   * or not the current auth user is an Operator */
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const roles = useSelector((state) => state.auth.roles);
  const [ready, setReady] = useState(false);

  // auth setup and listeners
  useEffect(() => {
    (async () => {
      await dispatch(setupCognito({ forceRefresh: false }));

      setReady(true);
    })();
  }, [isLoggedIn]);

  // handle authorization on route location update
  useEffect(() => {
    if (!ready || authorizedRoles.length === 0) {
      return;
    }

    // user is considered authorized to view this page
    // if they have some role that intersects with an
    // authorized role for this route
    const authorized = roles.some((userRole) => authorizedRoles.some((authorizedRole) => authorizedRole === userRole));

    if (!authorized) {
      navigate('/', { replace: true });
    }
  }, [ready, authorizedRoles, location.pathname]);

  // handle redirect to sign-in if user is not logged in
  useEffect(() => {
    if (!ready) {
      return;
    }

    if (!isLoggedIn && redirectToSignIn) {
      if (typeof redirectToSignIn === 'string') {
        navigate(redirectToSignIn, { replace: true });
      } else {
        navigate('/sign-in', { replace: true });
      }
    }
  }, [ready, isLoggedIn]);

  return ready ? children : <LoadingScreen />;
}

CognitoRoute.defaultProps = {
  redirectToSignIn: true,
  authorizedRoles: [],
};

export default CognitoRoute;

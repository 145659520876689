import { types as api } from '@mesa-labs/mesa-api';
import { ExternalVendorApi } from '@mesa-labs/mesa-api/dist/apis/externalVendor';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  getIdToken,
  prepareHeaders,
  ExternalVendorStatisticsMutex,
  useApi,
} from './api';
import { appendFilterParams } from '../../utils';

export const externalVendorsApi = createApi({
  reducerPath: 'externalVendorsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.api.externalVendorUrl,
    prepareHeaders,
  }),
  tagTypes: ['ExternalVendors', 'ExternalVendor', 'ExternalVendorClients', 'ExternalVendorDimensions', 'ExternalClients', 'ExternalClient', 'ExternalClientVendors', 'AllExternalClientVendors'],
  endpoints: (build) => ({
    getExternalVendors: build.query<readonly api.ExternalVendorDataResponse[], { externalVendorIdentifiers: readonly api.ExternalVendorIdentifier[]; }>({
      async queryFn({ externalVendorIdentifiers }) {
        const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
        const data = await externalVendorIdentifiers.reduce((p, externalVendorIdentifier) => p.then(async (res) => {
          const externalVendor = await externalVendorApi.getExternalVendor(externalVendorIdentifier.partnerId, externalVendorIdentifier.externalVendorId);
          return externalVendor ? [...res, externalVendor] : res;
        }), Promise.resolve([] as api.ExternalVendorDataResponse[]));
        return { data };
      },
    }),
    getExternalVendorSpend:
      build.query<api.ExternalVendorSpendResponse, api.ExternalVendorSpendFilterParams>({
        async queryFn(params) {
          const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
          const data = await externalVendorApi.getExternalVendorSpend(params);
          return { data };
        },
        providesTags: ['ExternalVendors'],
      }),
    getExternalVendorOnboardedSpend:
      build.query<api.InternalVendorSpendResponse, api.InternalVendorSpendFilterParams>({
        async queryFn(params) {
          return await ExternalVendorStatisticsMutex.runExclusive(async () => {
            const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
            const data = await externalVendorApi.getOnboardedSpend(params);
            return { data };
          });
        },
        providesTags: ['ExternalVendors'],
      }),
    getExternalClientSpend:
      build.query<api.ExternalClientSpendTimeSeriesResponse, api.ExternalClientSpendFilterParams>({
        async queryFn(params) {
          return await ExternalVendorStatisticsMutex.runExclusive(async () => {
            const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
            const data = await externalVendorApi.getExternalClientSpend(params);
            return { data };
          });
        },
        providesTags: ['ExternalVendors'],
      }),
    getExternalVendorPeriodStatistics:
      build.query<api.ExternalVendorPeriodStatisticsResponse, api.ExternalVendorPeriodStatisticsFilterParams>({
        async queryFn(params) {
          return await ExternalVendorStatisticsMutex.runExclusive(async () => {
            const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
            const data = await externalVendorApi.getExternalVendorPeriodStatistics(params);
            return { data };
          });
        },
      }),
    getExternalClientPeriodStatistics:
      build.query<api.IPagedResults<api.ExternalClientPeriodSpend>, api.ExternalClientPeriodStatisticsFilterParams>({
        async queryFn(params) {
          return await ExternalVendorStatisticsMutex.runExclusive(async () => {
            const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
            const data = await externalVendorApi.getExternalClientPeriodStatistics(params);
            return { data };
          });
        },
      }),
    getExternalVendorDimensions:
      build.query<api.ExternalVendorDimensionsResponse, api.ExternalVendorDimensionsFilters>({
        async queryFn(params) {
          const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
          const data = await externalVendorApi.getExternalVendorDimensions(params);
          return { data };
        },
        providesTags: ['ExternalVendorDimensions'],
      }),
    getAllExternalVendors:
      build.query<api.IPagedResults<api.ExternalVendorDataResponse>, api.ExternalVendorFilterParams & { readonly partnerId: number }>({
        async queryFn({ partnerId, ...params }) {
          const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
          const data = await externalVendorApi.getAllExternalVendors(partnerId, params);
          return { data };
        },
        providesTags: ['ExternalVendors'],
      }),
    getExternalVendor:
      build.query<api.ExternalVendorDataResponse | undefined, { readonly partnerId: number; readonly externalVendorId: string; readonly businessEntityScope: api.BusinessEntityScope }>({
        async queryFn({ partnerId, externalVendorId, businessEntityScope }) {
          const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
          const data = await externalVendorApi.getExternalVendor(partnerId, externalVendorId, { businessEntityScope });
          return { data };
        },
        providesTags: (result, error, id) => (result
          ? [{ type: 'ExternalVendor', ...id, ...result }]
          : ['ExternalVendor']),
      }),
    getExternalVendorClients:
      build.query<api.IPagedResults<api.ExternalVendorClientDataResponse>, api.ExternalVendorClientFilterParams & { readonly partnerId: number; readonly externalVendorId: string; }>({
        async queryFn({ partnerId, externalVendorId, ...params }) {
          const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
          const data = await externalVendorApi.getExternalVendorClients(partnerId, externalVendorId, params);
          return { data };
        },
        providesTags: (result, error, id) => (result
          ? [{ type: 'ExternalVendorClients', ...id, ...result }]
          : ['ExternalVendorClients']),
      }),
    getAllExternalClients:
      build.query<api.IPagedResults<api.ExternalClientDataResponse>, api.ExternalClientFilterParams & { readonly partnerId: number }>({
        async queryFn({ partnerId, ...params }) {
          const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
          const data = await externalVendorApi.getAllExternalClients(partnerId, params);
          return { data };
        },
        providesTags: ['ExternalClients'],
      }),
    getExternalClients: build.query<readonly api.ExternalClientDataResponse[], { partnerId: api.Partners; externalClientIds: readonly string[] }>({
      async queryFn({ partnerId, externalClientIds }, _queryApi, _extraOptions, fetchWithBQ) {
        const responses = await Promise.all(externalClientIds.map((externalClientId) => fetchWithBQ({
          url: `/partners/${partnerId}/external-clients/${externalClientId}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })));
        return {
          data: responses.filter((r) => !!r.data).map((r) => r.data as api.ExternalClientDataResponse),
        };
      },
      providesTags: (result) => (result
        ? result.map((ecd) => ({ type: 'ExternalClient', ...ecd }))
        : []),
    }),
    getExternalClient:
      build.query<api.ExternalClientDataResponse | undefined, { readonly partnerId: number; readonly externalClientId: string; readonly businessEntityScope: api.BusinessEntityScope }>({
        async queryFn({ partnerId, externalClientId, businessEntityScope }) {
          const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
          const data = await externalVendorApi.getExternalClient(partnerId, externalClientId, { businessEntityScope });
          return { data };
        },
        providesTags: (result, error, id) => (result
          ? [{ type: 'ExternalClient', ...id, ...result }]
          : ['ExternalClient']),
      }),
    getExternalClientVendors:
      build.query<api.IPagedResults<api.ExternalClientVendorDataResponse>, api.ExternalVendorFilterParams & { readonly partnerId: number; readonly externalClientId: string; }>({
        async queryFn({ partnerId, externalClientId, ...params }) {
          const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
          const data = await externalVendorApi.getExternalClientVendors(partnerId, externalClientId, params);
          return { data };
        },
        providesTags: (result, error, id) => (result
          ? [{ type: 'ExternalClientVendors', ...id, ...result }]
          : ['ExternalClientVendors']),
      }),
    getAllExternalClientVendors:
      build.query<api.IPagedResults<api.ExternalClientVendorDataResponse>, api.ExternalVendorFilterParams>({
        async queryFn(params) {
          const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
          const data = await externalVendorApi.getAllExternalClientVendors(params);
          return { data };
        },
        providesTags: ['AllExternalClientVendors']
      }),
    getWaptDistribution:
      build.query<api.WAPTDistributionResponse, api.WAPTDistributionRequest>({
        async queryFn(params) {
          return await ExternalVendorStatisticsMutex.runExclusive(async () => {
            const externalVendorApi = useApi((options) => new ExternalVendorApi(CONFIG.api.externalVendorUrl, options));
            const data = await externalVendorApi.getWaptDistribution(params);
            return { data };
          });
        },
      }),
  }),
});

export const {
  useGetExternalVendorSpendQuery,
  useLazyGetExternalVendorSpendQuery,
  useGetExternalVendorOnboardedSpendQuery,
  useLazyGetExternalVendorOnboardedSpendQuery,
  useGetExternalClientSpendQuery,
  useLazyGetExternalClientSpendQuery,
  useGetExternalVendorPeriodStatisticsQuery,
  useLazyGetExternalVendorPeriodStatisticsQuery,
  useGetExternalClientPeriodStatisticsQuery,
  useLazyGetExternalClientPeriodStatisticsQuery,
  useGetExternalVendorDimensionsQuery,
  useLazyGetExternalVendorDimensionsQuery,
  useGetAllExternalVendorsQuery,
  useLazyGetAllExternalVendorsQuery,
  useGetExternalVendorQuery,
  useGetExternalVendorClientsQuery,
  useGetAllExternalClientsQuery,
  useGetExternalClientsQuery,
  useGetExternalClientQuery,
  useGetExternalClientVendorsQuery,
  useGetWaptDistributionQuery,
  useLazyGetWaptDistributionQuery,
  useGetAllExternalClientVendorsQuery,
} = externalVendorsApi;

export const exportAllExternalVendors = async (partnerId: api.Partners, filterParams?: api.ExternalVendorFilterParams): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.externalVendorUrl}/partners/${partnerId}/external-vendors/csv/export`);
  appendFilterParams(url, filterParams);

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};

export const exportAllExternalClients = async (partnerId: api.Partners, filterParams?: api.ExternalClientFilterParams): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.externalVendorUrl}/partners/${partnerId}/external-clients/csv/export`);
  appendFilterParams(url, filterParams);

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};

export const exportExternalClientVendors = async (partnerId: api.Partners, externalClientId: string, filterParams?: api.ExternalClientVendorFilterParams): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.externalVendorUrl}/partners/${partnerId}/external-clients/${externalClientId}/external-vendors/csv/export`);
  appendFilterParams(url, filterParams);

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};

export const exportAllExternalClientVendors = async (filterParams?: api.ExternalClientVendorFilterParams): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.externalVendorUrl}/external-client-vendors/csv/export`);
  appendFilterParams(url, filterParams);

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};

export const exportClientPeriodSpend = async (filterParams?: api.ExternalClientPeriodStatisticsFilterParams): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.externalVendorUrl}/external-clients/statistics/period/csv/export`);
  appendFilterParams(url, filterParams);

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};

import { types as api } from '@mesa-labs/mesa-api';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DownloadButton, PaginationFooter, Search, Table, } from '@mesa-labs/mesa-ui';

import {
  exportAllExternalClientVendors,
  useGetAllExternalClientVendorsQuery,
} from '../../redux/api/externalVendors';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateExternalClientVendorsLimit,
  updateExternalClientVendorsPage,
  updateExternalClientVendorsSortDirection,
  updateExternalClientVendorsSortField,
  updateExternalClientVendorsTotalPages,
  updateSearchTerm
} from '../../redux/slices/externalClientVendors';
import { DefaultPaginationLimits } from './PaginationLimits';
import { useNavigate } from 'react-router-dom';
import { asExportFilter } from '../../utils';
import { FilterControls, FilterSection } from './Filters';
import useSyncQueryStringParamsToRedux from '../../hooks/useSyncQueryStringParamsToRedux';
import { ColumnProp } from '@mesa-labs/mesa-ui/dist/components/Table';

const ClientPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ExternalClientVendorColumns: ColumnProp[] = [
  {
    key: 'partnerName',
    sortField: 'partnerId',
    label: 'Partner',
    width: '10%',
  },
  {
    key: 'externalClientId',
    sortField: 'externalClientId',
    label: 'External Client Id',
    width: '10%',
  },
  {
    key: 'externalClientName',
    sortField: 'externalClientName',
    label: 'External Client Name',
    width: '15%',
  },
  {
    key: 'externalVendorId',
    sortField: 'externalVendorId',
    label: 'External Vendor Id',
    width: '10%',
  },
  {
    key: 'externalVendorName',
    sortField: 'externalVendorName',
    label: 'External Vendor Name',
    width: '15%',
  },
  {
    key: 'totalInvoiceSpendT12m',
    sortField: 'totalInvoiceSpendT12m',
    label: 'Client Total Invoice Spend (T12M)',
    type: 'currency',
    width: '10%',
  },
  {
    key: 'servicedInvoiceSpendT12m',
    sortField: 'servicedInvoiceSpendT12m',
    label: 'Client Funded Invoice Spend (T12M)',
    type: 'currency',
    width: '10%',
  },
  {
    key: 'defaultPaymentTermsDays',
    sortField: 'defaultPaymentTermsDays',
    label: 'Payment Terms (Days)',
    width: '10%',
  },
  {
    key: 'isInternalVendor',
    sortField: 'internalVendorId',
    label: 'Mesa Vendor?',
    width: '10%',
  },
];

type ExternalClientVendorsPageTemplate = {
  businessEntityScope: api.BusinessEntityScope;
};

function ExternalClientVendorsPageTemplate(props: ExternalClientVendorsPageTemplate): React.ReactElement | null {
  const { businessEntityScope } = props;
  const qualifier = businessEntityScope === api.BusinessEntityScope.External ? 'external' : 'internal';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchTerm = useSelector((state) => state.externalClientVendors.searchTerm) || undefined;
  const externalVendorsPage = useSelector((state) => state.externalClientVendors.externalVendorsPage) || 1;
  const externalVendorsTotalPages = useSelector((state) => state.externalClientVendors.externalVendorsTotalPages);
  const externalVendorsLimit = useSelector((state) => state.externalClientVendors.externalVendorsLimit) || DefaultPaginationLimits[0];
  const externalVendorsSortField = useSelector((state) => state.externalClientVendors.externalVendorsSortField) || 'totalInvoiceSpend';
  const externalVendorsSortDirection = useSelector((state) => state.externalClientVendors.externalVendorsSortDirection) || api.SortDirection.DESCENDING;
  const [searchInput, setSearchInput] = useState(searchTerm);

  useSyncQueryStringParamsToRedux({ sliceName: 'externalClientVendors' });

  const filter = {
    ...(searchTerm && {
      search: searchTerm,
    }),
    sortField: externalVendorsSortField,
    sortDirection: externalVendorsSortDirection,
    page: externalVendorsPage,
    limit: externalVendorsLimit.value,
    businessEntityScope
  };

  const {
    data: {
      data: externalClientVendors,
      total: totalExternalClientVendors,
    } = {},
  } = useGetAllExternalClientVendorsQuery({
    ...filter,
  });

  useEffect(() => {
    if (totalExternalClientVendors !== undefined) {
      const limitValue = externalVendorsLimit.value || externalVendorsLimit as unknown as number || DefaultPaginationLimits[0].value;
      dispatch(updateExternalClientVendorsTotalPages(Math.ceil(totalExternalClientVendors / limitValue)));
    }
  }, [totalExternalClientVendors, externalVendorsLimit]);

  useEffect(() => {
    if (externalVendorsTotalPages === undefined && externalVendorsPage !== 1) {
      dispatch(updateExternalClientVendorsPage(1));
    }
  }, []);

  const rows = (externalClientVendors || []).map((externalClientVendor) => ({
    ...externalClientVendor,
    id: `${externalClientVendor.partnerId}:${externalClientVendor.externalClientId}:${externalClientVendor.externalVendorId}`,
    isInternalVendor: !!externalClientVendor.internalVendorId,
    partnerName: api.getPartnerName(externalClientVendor.partnerId),
    currency: 'USD', // TODO: MESA-1457: Multi-currency support
  }))

  return (
    <ClientPageContainer>
      <FilterSection
        filterControl={(
          <FilterControls>
            <Search
              placeholder="Search by Client Name, Vendor Name, etc.... Press Enter to search, Escape to clear."
              value={searchInput || ''}
              onChange={(value) => setSearchInput(value)}
              onSubmit={(value) => dispatch(updateSearchTerm(value))}
              onFinalize={(value) => dispatch(updateSearchTerm(value))}
              expanded
              width="550px"
            />
            <DownloadButton
              text="Download CSV"
              data={() => exportAllExternalClientVendors({
                ...asExportFilter(filter),
              })}
              fileName="external-client-vendors.csv"
            />
          </FilterControls>
        )}
      >
      </FilterSection>

      <Table
        columns={ExternalClientVendorColumns}
        rows={rows}
        currentSortColumn={externalVendorsSortField}
        currentSortDirection={externalVendorsSortDirection}
        setSortField={(field: string) => dispatch(updateExternalClientVendorsSortField(field))}
        setSortDirection={(direction: api.SortDirection) => dispatch(updateExternalClientVendorsSortDirection(direction))}
        onRowClick={(row: Record<string, unknown>) => navigate(`/partners/${row.partnerId}/${qualifier}-clients/${row.externalClientId}`)}
        paginationComponent={(
          <PaginationFooter
            selectedLimit={externalVendorsLimit}
            onChange={(item) => {
              dispatch(updateExternalClientVendorsLimit(item));
              dispatch(updateExternalClientVendorsPage(1));
            }}
            currentPage={externalVendorsPage}
            totalPages={externalVendorsTotalPages || 1}
            onPrev={() => dispatch(updateExternalClientVendorsPage(externalVendorsPage - 1))}
            onNext={() => dispatch(updateExternalClientVendorsPage(externalVendorsPage + 1))}
          />
        )}
      />
    </ClientPageContainer>
  );
}

export default ExternalClientVendorsPageTemplate;

import React, { useEffect, useState } from 'react';
import { types as api } from '@mesa-labs/mesa-api';
import { Dropdown } from '@mesa-labs/mesa-ui';
import { DropdownOption } from '@mesa-labs/mesa-ui/dist/components/Dropdown';

import { toFilterEnumFromNumberEnum } from '../../utils';

const NoSelection = undefined;

const ValidPartnerSelections = toFilterEnumFromNumberEnum(api.Partners).filter((s) => s.value > 0).map((s) => ({ label: api.getPartnerName(s.value), value: s.value }));
const AllPartnerSelections = [{ label: 'All Partners', value: NoSelection as unknown as number }, ...ValidPartnerSelections];
const NoPartnerSelections = [{ label: '(No Selection)', value: NoSelection as unknown as number }, ...ValidPartnerSelections];

type PartnerSelectProps = {
  label?: string;
  selectedPartner: api.Partners | undefined;
  onSelectedPartnerChange: (partnerId: api.Partners | undefined) => void;
  allowAllPartners?: boolean;
  allowNoPartner?: boolean;
};

function PartnerSelect({
  label,
  selectedPartner,
  onSelectedPartnerChange,
  allowAllPartners,
  allowNoPartner,
}: PartnerSelectProps): React.ReactElement {
  const [partnerSelections, setPartnerSelections] = useState<DropdownOption<number>[]>(ValidPartnerSelections);

  useEffect(() => {
    const newPartnerSelections = allowAllPartners
      ? AllPartnerSelections :
      allowNoPartner
        ?
        NoPartnerSelections
        : ValidPartnerSelections;
    setPartnerSelections(newPartnerSelections);
  }, [allowAllPartners]);

  const handleOnChange = (value: api.Partners | undefined) => {
    const actualValue = (value as any).label ? (value as any).value : value;  // TODO: Underlaying Dropdown component sends the full option when the value is null/undefined
    onSelectedPartnerChange(actualValue);
  };

  return (
    <Dropdown
      label={label}
      options={partnerSelections}
      selectedOption={selectedPartner}
      onChange={handleOnChange}
      minWidth='100%'
    />
  );
}

PartnerSelect.defaultProps = {
  label: undefined,
  allowAllPartners: undefined,
  allowNoPartner: undefined
};

export default PartnerSelect;

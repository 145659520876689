import { types as api } from '@mesa-labs/mesa-api';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  CardContainer,
  PaginationFooter,
  ResourceCard,
  ResourceSection,
  Table,
} from '@mesa-labs/mesa-ui';
import { ResourceItemProp } from '@mesa-labs/mesa-ui/dist/components/ResourceCard';

import {
  useGetExternalVendorQuery, useGetExternalVendorClientsQuery,
} from '../../redux/api/externalVendors';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateCurrentExternalVendorId, updateExternalClientsLimit, updateExternalClientsPage, updateExternalClientsSortDirection, updateExternalClientsSortField, updateExternalClientsTotalPages,
} from '../../redux/slices/externalVendors';
import { DefaultPaginationLimits } from './PaginationLimits';

const ClientPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

type ExternalVendorPageTemplateProps = {
  businessEntityScope: api.BusinessEntityScope;
};

function ExternalVendorPageTemplate(props: ExternalVendorPageTemplateProps): React.ReactElement | null {
  const { businessEntityScope } = props;
  const { id: externalVendorId, partnerId } = useParams();
  const dispatch = useDispatch();
  const externalClientsPage = useSelector((state) => state.externalVendors.externalClientsPage) || 1;
  const externalClientsTotalPages = useSelector((state) => state.externalVendors.externalClientsTotalPages);
  const externalClientsLimit = useSelector((state) => state.externalVendors.externalClientsLimit) || DefaultPaginationLimits[0];
  const externalClientsSortField = useSelector((state) => state.externalVendors.externalClientsSortField);
  const externalClientsSortDirection = useSelector((state) => state.externalVendors.externalClientsSortDirection);
  const partnerIdParam = parseInt(partnerId || '', 10);

  useEffect(() => {
    if (externalVendorId) {
      dispatch(updateCurrentExternalVendorId(externalVendorId));
    }
  }, [externalVendorId]);

  const {
    data: externalVendor,
  } = useGetExternalVendorQuery({
    partnerId: partnerIdParam,
    externalVendorId: externalVendorId!,
    businessEntityScope
  }, { skip: !externalVendorId });

  const {
    data: {
      data: externalClients,
      total: totalExternalClients,
    } = {},
  } = useGetExternalVendorClientsQuery({
    partnerId: partnerIdParam,
    externalVendorId: externalVendorId!,
    excluded: false,
    page: externalClientsPage,
    limit: externalClientsLimit.value,
    sortField: externalClientsSortField,
    sortDirection: externalClientsSortDirection,
    businessEntityScope
  }, { skip: !externalVendorId });

  useEffect(() => {
    if (totalExternalClients !== undefined) {
      const limitValue = externalClientsLimit.value || externalClientsLimit as unknown as number || DefaultPaginationLimits[0].value;
      dispatch(updateExternalClientsTotalPages(Math.ceil(totalExternalClients / limitValue)));
    }
  }, [totalExternalClients, externalClientsLimit]);

  if (!externalVendor) {
    return null;
  }

  const externalVendorItems: (string | ResourceItemProp)[] = [
    { key: 'parterName', label: 'Partner' },
    { key: 'externalVendorId', width: '50%' },
    { key: 'isInternalVendor', label: 'Mesa?' },

    'email',
    'phoneNumber',
    { key: ['address', 'addressAdditional', 'city', 'state', 'zip', 'countryCode'], width: '50%' },

    { key: 'paymentMethod', type: 'enum' },
    'taxId',
    'locale',
    'withholdingPercent',

    'classification',
    'serviceType',
    { key: 'diverse', label: 'Diverse?', width: '50%' },

    { key: 'totalInvoiceAmountPriorYear', type: 'currency' },
    { key: 'totalInvoiceAmountCurrentYear', type: 'currency', width: '50%' },
    'hubSpotRecordId',

    { key: 'eligibleInvoiceSpend', label: 'Eligible Spend (T12M)', type: 'currency' },
    { key: 'eligibleInvoiceSpendT6m', label: 'Eligible Spend (T6M)', type: 'currency' },
    { key: 'percentageProjectedEligibleInvoiceSpendT6m', label: '% Projected Eligible Spend (T6M)', width: '50%' },

    { key: 'weightedEligibleAveragePaymentTenor', label: 'Eligible WAPT (T12M)' },
    { key: 'weightedEligibleAveragePaymentTenorT6m', label: 'Eligible WAPT (T6M)' },
    { key: 'weightedEligibleAverageInvoiceTenor', label: 'Eligible WAIT (T12M)' },
    { key: 'weightedEligibleAverageInvoiceTenorT6m', label: 'Eligible WAIT (T6M)' },

    { key: 'weightedEligibleAverageLedgerLatenessT6m', label: 'Eligible WALL (T6M)' },
    { key: 'weightedEligibleAverageLedgerLatenessT12m', label: 'Eligible WALL (T12M)', width: '75%' },

    { key: 'weightedEligibleAverageNet30AprT6m', type: 'percentage', label: 'Weighted Net 30 APR (T6M)' },
    { key: 'weightedEligibleAverageNet30AprT12m', type: 'percentage', label: 'Weighted Net 30 APR (T12M)' },
    { key: 'weightedEligibleAverageNet30YieldPricingT6m', type: 'percentage', label: 'Net 30 Rate for Ideal APR (T6M)' },
    { key: 'weightedEligibleAverageNet30YieldPricingT12m', type: 'percentage', label: 'Net 30 Rate for Ideal APR (T12M)' },
  ];

  const externalVendorAuditItems: (string | ResourceItemProp)[] = [
    { key: 'signedUpAt', type: 'datetime' },
    { key: 'activatedAt', type: 'datetime' },
    { key: 'deactivatedAt', type: 'datetime', width: '50%' },
  ];

  return (
    <ClientPageContainer>
      <CardContainer>
        <ResourceCard
          resource={{
            ...externalVendor,
            parterName: api.getPartnerName(externalVendor.partnerId),
            isInternalVendor: !!externalVendor.internalVendorId && !externalVendor.deactivatedAt,
            currency: 'USD', // TODO: MESA-1457: Multi-currency support
          }}
          title={externalVendor.externalVendorName || externalVendor.externalVendorId}
          items={externalVendorItems}
          auditItems={externalVendorAuditItems}
          columns={4}
        />
      </CardContainer>

      <ResourceSection
        title="Clients (T12M - Included Only)"
      >
        <Table
          columns={[
            {
              key: 'partnerName',
              sortField: 'partnerId',
              label: 'Partner',
              width: '10%',
            },
            {
              key: 'externalClientId',
              sortField: 'externalClientId',
              label: 'External Client Id',
              width: '10%',
            },
            {
              key: 'externalClientName',
              sortField: 'externalClientName',
              label: 'External Client Name',
              width: '20%',
            },
            {
              key: 'segment',
              sortField: 'segment',
              label: 'Client Segment',
              width: '20%',
            },
            {
              key: 'totalInvoiceSpend',
              sortField: 'totalInvoiceSpend',
              label: 'Vendor Invoice Spend (T12M)',
              type: 'currency',
              width: '20%',
            },
            {
              key: 'totalInvoiceSpendT6m',
              sortField: 'totalInvoiceSpendT6m',
              label: 'Vendor Invoice Spend (T6M)',
              type: 'currency',
              width: '20%',
            },
          ]}
          currentSortColumn={externalClientsSortField}
          currentSortDirection={externalClientsSortDirection}
          setSortField={(field: string) => dispatch(updateExternalClientsSortField(field))}
          setSortDirection={(direction: api.SortDirection) => dispatch(updateExternalClientsSortDirection(direction))}
          rows={
            (externalClients || []).map((externalClient) => ({
              ...externalClient,
              id: `${externalClient.partnerId}:${externalClient.externalClientId}`,
              partnerName: api.getPartnerName(externalClient.partnerId),
              currency: 'USD', // TODO: MESA-1457: Multi-currency support
            }))
          }
          paginationComponent={(
            <PaginationFooter
              selectedLimit={externalClientsLimit}
              onChange={(item) => {
                dispatch(updateExternalClientsLimit(item));
                dispatch(updateExternalClientsPage(1));
              }}
              currentPage={externalClientsPage}
              totalPages={externalClientsTotalPages || 0}
              onPrev={() => dispatch(updateExternalClientsPage(externalClientsPage - 1))}
              onNext={() => dispatch(updateExternalClientsPage(externalClientsPage + 1))}
            />
          )}
        />
      </ResourceSection>
    </ClientPageContainer>
  );
}

export default ExternalVendorPageTemplate;

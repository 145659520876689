import React, { useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useSelector } from '../../redux/hooks';

const NavContainer = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 18px;
  justify-content: space-between;
`;

const NavDropdownContainer = styled(motion.div) <{ zIndex?: number }>`
  position: relative;
`;

const NavDropdownLabel = styled.div<{ active: boolean, initiallyHidden: boolean }>`
  background: ${(props) => (props.active && 'rgba(129, 156, 251, 0.1)') || 'transparent'};
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 14px;
  position: relative;
  transition: background 200ms ease-in-out;

  &:hover {
    ${(props) => props.initiallyHidden && `
      background: rgba(129, 156, 251, 0.1);
    `}
  }
`;

const NavDropdownLabelBridge = styled.div`
  height: 10px;
  position: absolute;
  bottom: -10px;
  left: 0px;
  width: 100%;
`;

const NavDropdownLinks = styled(motion.div) <{ initiallyHidden: boolean, width?: number }>`
  background: ${(props) => props.theme.colors.White};
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1);
  display: none;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
  font-size: 14px;
  overflow: hidden;
  padding: 8px;
  width: ${(props) => (props.width && `${props.width}px`) || 'auto'};

  ${(props) => props.initiallyHidden && `
    left: 50%;
    position: absolute;
    top: 48px;
    transform: translateX(-50%);
  `}
`;

const NavDropdownLink = styled(Link)`
  border-radius: 8px;
  color: #475467;
  cursor: pointer;
  padding: 12px;
  transition: background 100ms ease-in-out;
  white-space: nowrap;

  &:hover {
    background: #FCFCFC;
    color: #333333;
  }
`;

const ROUTES = {
  coreBasicEntities: [
    {
      label: 'Home',
      route: '/',
    },
    {
      label: 'Vendors',
      route: '/vendors',
    },
    {
      label: 'Programs',
      route: '/programs',
    },
    {
      label: 'Invoices',
      route: '/invoices',
    },
    {
      label: 'Invoice Line Items',
      route: '/invoice-line-items',
    },
    {
      label: 'Transfers',
      route: '/customer-batch-transfers',
    },
  ],
  coreBusinessInternals: [
    {
      label: 'Invoice Approvals',
      route: '/invoice-approvals',
    },
    {
      label: 'Invoice Adjustment Approvals',
      route: '/invoice-adjustment-approvals',
    },
    {
      label: 'Clients',
      route: '/clients',
    },
    {
      label: 'Enablement',
      route: '/enablement',
    },
    {
      label: 'Onboardings',
      route: '/onboardings',
    },
    {
      label: 'Abandoned Users',
      route: '/abandoned-users',
    },
    {
      label: 'Mesa Service Agreements',
      route: '/service-agreements',
    },
    {
      label: 'JLL Service Agreements',
      route: '/partner-service-agreements/partners/1',
    },
    {
      label: 'Collections',
      route: '/collections',
    },
    {
      label: 'Reconciliation',
      route: '/reconciliation',
    },
    {
      label: 'Batch Sweeps',
      route: '/batch-sweeps',
    },
    {
      label: 'Adjustments',
      route: '/adjustments',
    },
    {
      label: 'Eligible Invoices',
      route: '/eligible-invoices',
    },
    {
      label: 'Anomalies',
      route: '/anomalies',
    },
    {
      label: 'Tasks',
      route: '/tasks',
    },
    {
      label: 'Facilitators',
      route: '/facilitators',
    },
    {
      label: 'Vendor Feedback',
      route: '/vendor-feedback',
    },
  ],
  miscellaneous: [
    {
      label: 'Partner Integrations',
      route: '/partner-integrations',
    },
    {
      label: 'Statistics',
      route: '/statistics',
    },
    {
      label: 'KPIs',
      route: '/key-performance-indicators',
    },
    {
      label: 'Revenue',
      route: '/revenue',
    },
    {
      label: 'Cohorts',
      route: '/cohorts',
    },
    {
      label: 'Accelerated Payout Statistics',
      route: '/accelerated-payout-statistics',
    },
    {
      label: 'Cash Flow Projections',
      route: '/projections',
    },
    {
      label: 'Configuration Settings',
      route: '/configuration-settings',
    },
    {
      label: 'AI Assistant',
      route: '/ai-assistant',
    },
  ],
  internalData: [
    {
      label: 'Internal Vendors',
      route: '/internal-vendors',
    },
    {
      label: 'Internal Clients',
      route: '/internal-clients',
    },
    {
      label: 'Internal Client Vendors',
      route: '/internal-client-vendors',
    },
  ],
  externalData: [
    {
      label: 'External Vendors',
      route: '/external-vendors',
    },
    {
      label: 'External Clients',
      route: '/external-clients',
    },
    {
      label: 'External Client Vendors',
      route: '/external-client-vendors',
    },
    {
      label: 'External Vendor Statistics',
      route: '/external-vendor-statistics',
    },
  ],
};

type PageMenuProps = {
  initial: 'hidden' | 'hover',
};

function PageMenu({ initial }: PageMenuProps): React.ReactElement {
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const canViewBasicEntities = useSelector((state) => state.auth.canViewBasicEntities);
  const canViewBusinessInternals = useSelector((state) => state.auth.canViewBusinessInternals);
  const canViewBasicNav = useMemo(() => isLoggedIn, [isLoggedIn]);

  const isActiveLabel = (routes: { label: string, route: string }[]) => {
    if (initial === 'hover') {
      return false;
    }

    return routes.some((route) => route.route === location.pathname);
  }

  if (!isLoggedIn) {
    return <></>;
  }

  return (
    <NavContainer>
      <AnimatePresence>
        {canViewBasicNav && (
          <NavDropdownContainer initial={initial} whileHover="hover" exit={initial}>
            <NavDropdownLabel
              initiallyHidden={initial === 'hidden'}
              active={isActiveLabel([...ROUTES.coreBasicEntities, ...ROUTES.coreBusinessInternals])}
            >
              Core Platform
              <NavDropdownLabelBridge />
            </NavDropdownLabel>
            <NavDropdownLinks
              initiallyHidden={initial === 'hidden'}
              variants={{
                hidden: {
                  display: 'none',
                  opacity: 0,
                },
                hover: {
                  display: 'grid',
                  opacity: 1,
                },
              }}
              transition={{
                type: 'spring',
                duration: 1,
              }}
            >
              {canViewBasicEntities && ROUTES.coreBasicEntities.map((route) => <NavDropdownLink key={route.route} to={route.route}>{route.label}</NavDropdownLink>)}
              {canViewBusinessInternals && ROUTES.coreBusinessInternals.map((route) => <NavDropdownLink key={route.route} to={route.route}>{route.label}</NavDropdownLink>)}
            </NavDropdownLinks>
          </NavDropdownContainer>
        )}
      </AnimatePresence>
      <AnimatePresence>
        <NavDropdownContainer initial={initial} whileHover="hover" exit={initial}>
          <NavDropdownLabel
            initiallyHidden={initial === 'hidden'}
            active={isActiveLabel(ROUTES.miscellaneous)}
          >
            Miscellaneous
            <NavDropdownLabelBridge />
          </NavDropdownLabel>
          <NavDropdownLinks
            initiallyHidden={initial === 'hidden'}
            variants={{
              hidden: {
                display: 'none',
                opacity: 0,
              },
              hover: {
                display: 'grid',
                opacity: 1,
              },
            }}
            transition={{
              type: 'spring',
              duration: 1,
            }}
          >
            {ROUTES.miscellaneous.map((route) => <NavDropdownLink key={route.route} to={route.route}>{route.label}</NavDropdownLink>)}
          </NavDropdownLinks>
        </NavDropdownContainer>
      </AnimatePresence>
      <AnimatePresence>
        <NavDropdownContainer initial={initial} whileHover="hover" exit={initial}>
          <NavDropdownLabel
            initiallyHidden={initial === 'hidden'}
            active={isActiveLabel(ROUTES.internalData)}
          >
            Internal Data
            <NavDropdownLabelBridge />
          </NavDropdownLabel>
          <NavDropdownLinks
            initiallyHidden={initial === 'hidden'}
            variants={{
              hidden: {
                display: 'none',
                opacity: 0,
              },
              hover: {
                display: 'flex',
                opacity: 1,
              },
            }}
            transition={{
              type: 'spring',
              duration: 1,
            }}
          >
            {ROUTES.internalData.map((route) => <NavDropdownLink key={route.route} to={route.route}>{route.label}</NavDropdownLink>)}
          </NavDropdownLinks>
        </NavDropdownContainer>
      </AnimatePresence>
      <AnimatePresence>
        <NavDropdownContainer initial={initial} whileHover="hover" exit={initial}>
          <NavDropdownLabel
            initiallyHidden={initial === 'hidden'}
            active={isActiveLabel(ROUTES.externalData)}
          >
            External Data
            <NavDropdownLabelBridge />
          </NavDropdownLabel>
          <NavDropdownLinks
            initiallyHidden={initial === 'hidden'}
            variants={{
              hidden: {
                display: 'none',
                opacity: 0,
              },
              hover: {
                display: 'flex',
                opacity: 1,
              },
            }}
            transition={{
              type: 'spring',
              duration: 1,
            }}
          >
            {ROUTES.externalData.map((route) => <NavDropdownLink key={route.route} to={route.route}>{route.label}</NavDropdownLink>)}
          </NavDropdownLinks>
        </NavDropdownContainer>
      </AnimatePresence>
    </NavContainer>
  );
}

export default PageMenu;

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './components/App';

const container = document.querySelector('#root');
const root = createRoot(container!);

// force reload the page if vite encounters an error dynamically loading a
// module.
window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

root.render(
  <App />
);

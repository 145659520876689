import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector, } from 'react-redux';

import { AppDispatch, RootState } from '../store';
import { MouseEvent } from 'react';

export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export type NavigateWithModifiersFunction = (to: string, event: MouseEvent<HTMLDivElement>) => void;
export const navigateWithModifiers: NavigateWithModifiersFunction = ((to: string, event: MouseEvent<HTMLDivElement>) => {
  if (event && (event.ctrlKey || event.metaKey)) {
    window.open(to, '_blank');
  } else {
    window.open(to, '_self');
  }
})
